:root {
  @each $color, $value in $colors {
    --bs-#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors {
    --bs-#{$color}: #{$value};
  }

  --bs-font-sans-serif: #{inspect($font-family-sans-serif)};
  --bs-font-monospace: #{inspect($font-family-monospace)};
  --bs-gradient: #{$gradient};
}
