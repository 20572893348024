/* 04.9 Newsletter CSS */


.newsletter-section{
    position: relative;

    & .shape {
        position: absolute;
        z-index: -1;

        &-1 {
            top: 0;
            left: 0;
            right: 0;
            text-align: center;
            margin: 0 auto;
        }
    }

    // Responsive
    @media #{$large-mobile}{
        & .shape {
    
            &-1 {
                width: 250px;
            }
        }
    }
}

.newsletter-form{
    max-width: 570px;
    margin: 0 auto;
    margin-top: 50px;
    // Responsive
    @media #{$large-mobile}{
        max-width: 100%;
        margin-top: 30px;
    }
    &.form-border{
        form{
            input{
               border-color: $primary; 
            }
        }
    }
    form{
        display: flex;
        // Responsive
        @media #{$large-mobile}{
            flex-wrap: wrap;
        }
        input{
            border-width: 1px 0 1px 1px;
            border-radius: 5px 0 0 5px;
            min-height: 40px;
            // min-height:52px
            padding: 3px 20px;
            // Responsive
            @media #{$large-mobile}{
                border-width: 1px;
                border-radius: 5px;
                margin-bottom: 15px;
            }
        }
        button{
            border-radius: 0 5px 5px 0;
            width: 160px;
            // Responsive
            @media #{$large-mobile}{
                border-radius: 5px;
            }
        }
    }
}









