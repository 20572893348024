.snackbar {
    height: min-content !important;
    display: flex;
}
.css-u8t4tp-MuiPaper-root-MuiAlert-root .MuiAlert-icon {
    color: #fff !important;
}
.successalert {
    background-color: #34a4eb !important;
    color: white !important;
    padding: 0px 30px !important;
}

.warnigalert {
    background-color: #dd6f15 !important;
    color: white !important;
    padding: 0px 30px !important;
}
.css-1vooibu-MuiSvgIcon-root {
    color: white !important;
}