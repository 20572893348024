.coming {
    height: 100%;
    position: relative;
    &:before {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: #2a5555;
        opacity: 0.7;
        z-index: 1;
    }
    video {
        position: fixed;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        transform: translateX(-50%) translateY(-50%);
        z-index: 0;
    }
    .coming-soon {
        position: relative;
        overflow: hidden;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.85);
        }
        .coming-soon-content {
            position: relative;
            max-width: 40rem;
            padding-top: 5rem;
            padding-bottom: 5rem;
            h3 {
                font-size: 72px;
                line-height: 1.2;
                color: $white;
                margin-bottom: 40px;
            }
            p {
                font-size: 20px;
                font-weight: 500;
                line-height: 1.67;
                color: $white;
            }
        }
    }
}

@media (min-width: 992px) {
    .coming-soon {
        height: 100%;
        width: 75vw;
        min-height: 0;
        padding-bottom: 0;
        &:before {
            transform: skewX(-9deg);
            transform-origin: top right;
        }
        .coming-soon-content {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 2rem;
            padding-right: 9rem;
            h3 {
                font-size: 36px;
                margin-bottom: 20px;
            }
            p {
                font-size: 16px;
            }
        }
    }
}
@media (min-width: 1200px) {
    .coming-soon {
        width: 65vw;
    }
}
.social-icons {
    position: relative;
    z-index: 2;
}
.social-icons .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: 4rem;
    width: 4rem;
    border-radius: 100rem;
}

@media (min-width: 992px) {
    .social-icons {
        position: absolute;
        height: 100%;
        top: 0;
        right: 2.5rem;
        width: auto;
    }
}
