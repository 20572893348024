/*----------------------------------------*/
/*  01. Template default CSS
/*----------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

/* Common Style */

*,
*::after,
*::before {
  box-sizing: border-box;
}

html,
body,
.site-wrapper {
  height: 100%;
}

body {
  font-family: $body-font;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  line-height: 1.74;

  position: relative;

  visibility: visible;
  overflow-x: hidden;
  letter-spacing: 0;

  color: $secondary;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font;
  font-weight: 700;
  line-height: 1.3;

  margin-top: 0;

  color: $heading-color;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  line-height: inherit;

  margin: 0;
}

h1,
.h1 {
  @media #{$large-mobile} {
  }

  @media #{$small-mobile} {
  }
}

h2,
.h2 {
  @media #{$large-mobile} {
  }

  @media #{$small-mobile} {
  }
}

h3,
.h3 {
  @media #{$large-mobile} {
  }

  @media #{$small-mobile} {
  }
}

h4,
.h4 {
  @media #{$large-mobile} {
  }

  @media #{$small-mobile} {
  }
}

h5,
.h5 {
  @media #{$large-mobile} {
  }

  @media #{$small-mobile} {
  }
}

h6,
.h6 {
  @media #{$large-mobile} {
  }

  @media #{$small-mobile} {
  }
}

p:last-child {
  margin-bottom: 0;
}

a,
button {
  line-height: inherit;

  display: inline-block;

  cursor: pointer;
  text-decoration: none;

  color: inherit;
}

a,
button,
img,
input {
  transition: $transition;
}

*:focus {
  outline: none !important;
}

a:focus {
  text-decoration: none;

  color: inherit;
  outline: none;
}

a:hover {
  text-decoration: none;

  color: $primary;
}

button,
input[type="submit"] {
  cursor: pointer;
}

img {
  max-width: 100%;
}

input,
textarea {
  @include placeholder {
    opacity: 1;
  }
}

ul {
  &:last-child {
    margin-bottom: 0;
  }

  & li {
  }
}

hr {
  border-top-width: 2px;
}

svg:not(:root) {
  overflow: hidden;
}

/* Common Classes */
.section-border-bottom {
  border-bottom: 1px solid #e7e7e7;
}

.fix {
  overflow: hidden;
}
.before-zindex-9 {
  position: relative;
  &::before {
    z-index: 9 !important;
  }
}
.box-shadow {
  box-shadow: 0 0 40px rgba(51, 51, 51, 0.1);
}

.section,
.main-wrapper {
  float: left;

  width: 100%;
}

.bg-color-1 {
  background-color: #f8faff;
}

.bg-primary-blue {
  background-color: #f8faff;
}

.Player__videoContainer___2TVqS {
  width: 100% !important;
}

.overlay {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(
      to right,
      rgba(3, 15, 39, 0.7) 0%,
      rgba(3, 15, 39, 0) 100%
    );
  }
}
.overlay-two {
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #030f27;
    opacity: 0.7;
    z-index: -1;
  }
}

@for $i from 0 through 20 {
  [data-overlay="#{$i * .1}"] {
    &::before {
      opacity: 0.1 * $i;
    }
  }
}

/* Background Image */
[data-bg-image] {
  background-repeat: repeat;
  background-position: top center;

  &:not(.bg-pattern) {
  }
}

.section-bg-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}

/* Scroll Up */

.scroll-top {
  position: fixed;
  right: 30px;
  bottom: -60px;
  z-index: 999;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
  display: block;
  padding: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  border: 0;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  background-color: $primary;
  background-size: 200% auto;
  background-position: left center;
  color: $white;
  transition: $transition;
  overflow: hidden;

  @media #{$extra-small-mobile} {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }

  &.show {
    visibility: visible;
    opacity: 1;
    bottom: 60px;
  }

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    color: $white;
    transition: $transition;
  }

  .arrow-top {
    transform: translate(-50%, -50%);
  }

  .arrow-bottom {
    transform: translate(-50%, 80px);
  }

  &:hover {
    background-position: right center;
    .arrow-top {
      transform: translate(-50%, -80px);
    }
    .arrow-bottom {
      transform: translate(-50%, -50%);
    }
  }
}

/* swiper default styles */
.swiper-pagination {
  position: static;
  display: block;
  line-height: 1;
  margin-top: 20px;
  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    border: 0;
    border-radius: 0;
    background: transparent;
    opacity: 1;
    position: relative;
    outline: none;
    margin-right: 20px;
    margin: 0 8px !important;
    &:last-child {
      margin-right: 0;
    }
    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100%;
      width: 8px;
      height: 8px;
      background: #d8d8d8;
      z-index: 1;
      transition: $transition;
    }

    &:hover {
      &:before {
        width: 12px;
        height: 12px;
        opacity: 1;
        background: $heading-color;
      }
    }
  }

  .swiper-pagination-bullet-active {
    &:before {
      width: 12px;
      height: 12px;
      opacity: 1;
      background: $heading-color;
    }
  }
}

.swiper-pagination.swiper-pagination-white {
  & .swiper-pagination-bullet {
    margin: 8px;
    &:hover {
      &:before {
        width: 12px;
        height: 12px;
        opacity: 1;
        background: #fff;
      }
    }
  }
  & .swiper-pagination-bullet-active::before {
    opacity: 1;
    background: #fff;
    width: 16px;
    height: 16px;
    box-shadow: 0 0 9px #ededed;
  }
}

.swiper-nav-button {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    visibility 0.1s linear 2s, opacity 0.1s linear 2s;
  background-image: none;
  text-align: center;
  user-select: none;
  outline: none;
  width: 48px;
  height: 48px;
  font-size: 24px;
  color: #6d70a6;
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
  opacity: 0;
  visibility: hidden;
  transform: translate(0, -50%);
  margin: 0;
  top: 50%;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: inherit;
    color: #fff;
    background: #fff;
  }
}
.swiper-nav-button:hover {
  color: #fff;
  &::before {
    color: #fff;
    background: $heading-color;
  }
}
//   .swiper-container {
//       transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
//   }
.swiper-container:hover {
  & .swiper-nav-button {
    opacity: 1;
    visibility: visible;
  }
}

.swiper-nav-button i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.swiper-nav-button i::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f104";
}
.swiper-nav-prev i::before {
  content: "\f104";
}
.swiper-button-next i::before {
  content: "\f105";
}
//   .swiper-wrapper {
//       transition-timing-function: linear !important;
//   }
