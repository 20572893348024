/* 04.05 CTA CSS */

.icon-up-down-animation {
    position: relative;
    z-index: 9;
    .flex-icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-self: center;
        & .shape {
            width: 120px;
            height: 120px;
            z-index: -1;
            margin: 30px;

            img {
                box-shadow: 0 0 40px 0 rgba(51, 51, 51, 0.1);
                border-radius: 20px;
                width: 100px;
                height: 100px;
            }

            &-1 {
                animation: up-down infinite 4s ease-in-out;
            }
            &-2 {
                animation: up-down infinite 5s ease-in-out;
            }
            &-3 {
                animation: up-down infinite 6s ease-in-out;
            }
            &-4 {
                animation: up-down infinite 4s ease-in-out;
            }
            &-5 {
                animation: up-down infinite 5s ease-in-out;
            }
            &-6 {
                animation: up-down infinite 6s ease-in-out;
            }
        }
    }
}

// /* Up Down Animation */
// .up-down {
//     animation: up-down infinite 2s ease-in-out;
// }
// @keyframes up-down {
//     50% {
//         transform: translateY(30px);
//     }
// }

/* 04.05 Core pillars CSS */

.pillars-icon-up-down-animation {
    position: relative;
    z-index: 9;
    .pillar-icon {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-self: center;
        & .shape {
            width: 200px;
            height: 120px;
            z-index: -1;
            margin: 10px;

            img {
                box-shadow: 0 0 40px 0 rgba(51, 51, 51, 0.1);
                border-radius: 20px;
                width: 100px;
                height: 100px;
            }

            &-1 {
                animation: up-down infinite 3s ease-in-out;
            }
            &-2 {
                animation: up-down infinite 5s ease-in-out;
            }
            &-3 {
                animation: up-down infinite 6s ease-in-out;
            }
            &-4 {
                animation: up-down infinite 4s ease-in-out;
            }
        }
    }
}

/* Up Down Animation */
.up-down {
    animation: up-down infinite 2s ease-in-out;
}
@keyframes up-down {
    50% {
        transform: translateY(30px);
    }
}
