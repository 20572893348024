// Container mixins

@mixin make-container($gutter: $container-padding-x) {
  --bs-gutter-x: #{$gutter};

  width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-right: auto;
  margin-left: auto;
}
