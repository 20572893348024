.table {
  --bs-table-bg: #{$table-bg};
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #{$table-striped-color};
  --bs-table-striped-bg: #{$table-striped-bg};
  --bs-table-active-color: #{$table-active-color};
  --bs-table-active-bg: #{$table-active-bg};
  --bs-table-hover-color: #{$table-hover-color};
  --bs-table-hover-bg: #{$table-hover-bg};

  width: 100%;
  margin-bottom: $spacer;
  color: $table-color;
  vertical-align: $table-cell-vertical-align;
  border-color: $table-border-color;

  > :not(caption) > * > * {
    padding: $table-cell-padding-y $table-cell-padding-x;
    background-color: var(--bs-table-bg);
    background-image: linear-gradient(
      var(--bs-table-accent-bg),
      var(--bs-table-accent-bg)
    );
    border-bottom-width: $table-border-width;
  }

  > tbody {
    vertical-align: inherit;
  }

  > thead {
    vertical-align: bottom;
  }

  > :not(:last-child) > :last-child > * {
    border-bottom-color: $table-group-separator-color;
  }
}

.caption-top {
  caption-side: top;
}

.table-sm {
  > :not(caption) > * > * {
    padding: $table-cell-padding-y-sm $table-cell-padding-x-sm;
  }
}

.table-bordered {
  > :not(caption) > * {
    border-width: $table-border-width 0;

    > * {
      border-width: 0 $table-border-width;
    }
  }
}

.table-borderless {
  > :not(caption) > * > * {
    border-bottom-width: 0;
  }
}

.table-striped {
  > tbody > tr:nth-of-type(#{$table-striped-order}) {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color);
  }
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover {
  > tbody > tr:hover {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color);
  }
}

@each $color, $value in $table-variants {
  @include table-variant($color, $value);
}

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-down($breakpoint) {
    .table-responsive#{$infix} {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}
