/*----------------------------------------*/
/*  12. Footer CSS
/*----------------------------------------*/

.footer-bg-color {
    background-color: #030e22;
}

.footer-section {
    padding: 30px 0 0px;
    overflow: hidden;
    // Responsive
    @media #{$tablet-device} {
        padding: 60px 0 0px;
        .container {
            text-align: auto;
            .row {
                .footer-widget {
                    margin-left: 0;
                    margin-top: 0;
                }
            }
        }
    }
    @media #{$large-mobile} {
        padding: 40px 0 0px;
    }
    .container {
        .row {
            display: flex;
            justify-content: center;
            .footer-widget {
                text-align: left;
                margin-top: 20px;

                .footer-widget-content {
                    a {
                        &:hover {
                            text-decoration: none;
                            color: #fe0040;
                            transition: 500ms;
                        }
                    }
                }
            }
        }
    }
}

.copyright {
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    padding: 20px 15px;
    border-top: 1px solid $gray-200;
    color: $white;
    margin-bottom: 0;
}
