/* 04.02 Brand CSS */

.brand-list {
    transition: $transition;
    &:hover {
        .brand {
            span {
                display: block;
                img {
                    opacity: 0.5;
                    cursor: pointer;
                }
            }
        }
    }
    .brand {
        display: flex;
        justify-content: center;
        align-self: center;
        span {
            display: block;
            img {
                opacity: 1;
            }
        }
        &:hover {
            span {
                img {
                    opacity: 1;
                }
            }
        }
    }

    .brand-slider {
        .brand-slider-nav {
            cursor: pointer;
            background: rgba(0, 0, 0, 0.25);
            width: 50px;
            height: 50px;
            line-height: 100px;
            display: flex;
            text-align: center;
            color: $white;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            border-radius: 50% !important;
            &::after {
                display: none;
            }
            & i {
                font-size: 80px;
                line-height: 1;
                vertical-align: middle;
            }
            &:hover {
                color: var(--primary);
            }
            // Responsive
            @media #{$laptop-device} {
                width: 40px;
                height: 40px;
                line-height: 80px;
                & i {
                    font-size: 30px;
                }
            }
            @media #{$tablet-device} {
                width: 40px;
                height: 40px;
                line-height: 60px;
                & i {
                    font-size: 24px;
                }
            }
            @media #{$desktop-device} {
                width: 50px;
                height: 50px;
                line-height: 60px;
                & i {
                    font-size: 24px;
                }
            }
            @media #{$large-mobile} {
                width: 50px;
                height: 50px;
                line-height: 50px;
                & i {
                    font-size: 24px;
                }
            }
        }
        .brand-slider-prev {
            left: 0px;
        }
        .brand-slider-next {
            right: 0px;
            &::after {
                display: none;
            }
        }
        &:hover {
            .brand-slider-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
    .swiper-slide-active {
        & .intro-content {
            & > * {
                animation-name: fadeInUp;
                &:nth-child(1) {
                    animation-delay: 0.5s;
                }
                &:nth-child(2) {
                    animation-delay: 1s;
                }
                &:nth-child(3) {
                    animation-delay: 1.5s;
                }
                &:nth-child(4) {
                    animation-delay: 2s;
                }
                &:nth-child(5) {
                    animation-delay: 2.5s;
                }
                &:nth-child(6) {
                    animation-delay: 3s;
                }
            }
        }
    }
}
